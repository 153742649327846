import React from 'react';
import { Home } from 'Molecules/';
import { Header } from 'Atoms/';

const Index = () => {
  return (
    <>
      <Header />
      <main role='main'>
        <Home />
      </main>
    </>
  );
};

export default Index;

export const getServerSideProps = async ({ req }) => {
  const { cookies } = req;
  if (cookies.token) {
    return {
      redirect: {
        destination: '/dashboard',
        permanent: false,
      },
    };
  }
  return { props: {} };
};
